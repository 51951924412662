import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { deleteProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import EditProduct from "./EditProduct";

const AllProducts = () => {
  const { products, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const handleEdit = (data) => {
    setData(data);
    setOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteProduct(id));
    window.location.reload();
  };

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  const columns = [
    { field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "orgprice",
      headerName: "Original Price",
      minWidth: 0,
      flex: 0,
      hide: true,
    },
    {
      field: "stock",
      headerName: "Stock",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },

    {
      field: "tags",
      headerName: "Tags",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 0,
      flex: 0,
      hide: true,
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 0,
      flex: 0,
      hide: true,
    },
    {
      field: "Edit",
      flex: 0.4,
      minWidth: 50,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (data) => {
        return (
          <>
            <Button onClick={() => handleEdit(data)}>
              <AiOutlineEdit size={20} />
            </Button>
          </>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.4,
      minWidth: 50,
      headerName: "",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  products &&
    products.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price: "Rs " + item.discountPrice,
        ogprice: "Rs " + item.originalPrice,
        stock: item.stock,
        tags: item.tags,
        category: item.category,
        description: item.description,
      });
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
          {open && <EditProduct setOpen={setOpen} data={data} />}
        </div>
      )}
    </>
  );
};

export default AllProducts;
