import {
  AiFillFacebook,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";

export const socialMediaData = [
  {
    id: "fb",
    icon: (
      <AiFillFacebook
        size={25}
        className="cursor-pointer hover:text-[#3b5998]"
      />
    ),
    link: "https://www.facebook.com/gamestoreace",
  },
  {
    id: "tw",
    icon: (
      <AiOutlineTwitter
        size={25}
        className="hover:text-[#1DA1F2]"
        style={{ marginLeft: "15px", cursor: "pointer" }}
      />
    ),
    link: "https://twitter.com/kaydensou",
  },
  {
    id: "yt",
    icon: (
      <AiFillYoutube
        size={25}
        className="hover:text-[#FF0000]"
        style={{ marginLeft: "15px", cursor: "pointer" }}
      />
    ),
    link: "https://www.youtube.com/channel/UC32VFGkEHxEIcb6FUgHdT6w",
  },
];
