import React from "react";
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Events/Events";
// import Sponsored from "../components/Route/Sponsored";
import Helmet from "../components/Helmet/Helmet";
import Footer from "../components/Layout/Footer";

const HomePage = () => {
  return (
    <Helmet title="Home">
      <div>
        <Header activeHeading={1} />
        <Hero />
        <Categories />
        <BestDeals />
        <Events />
        <FeaturedProduct />
        {/* <Sponsored /> */}
        <Footer />
      </div>
    </Helmet>
  );
};

export default HomePage;
