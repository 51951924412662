import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Imepay_QR from "../../assets/images/Payments/Imepay_QR.jpg";
import Khalti_QR from "../../assets/images/Payments/Khalti_QR.jpg";
import Bank_QR from "../../assets/images/Payments/Bank_QR.jpg";
import eSewa_QR from "../../assets/images/Payments/eSewa_QR.jpg";

const Payment = () => {
  return (
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <PaymentInfo />
        </div>
        <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
          <CartData />
        </div>
      </div>
    </div>
  );
};

const PaymentInfo = () => {
  const [select, setSelect] = useState(1);
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    e.preventDefault();

    let files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const paymentHandler = (e) => {
    e.preventDefault();
    navigate("/order/success/fdbxf9848");
  };

  return (
    <div className="w-full 800px:w-[95%] bg-[#fff] rounded-md p-5 pb-8">
      {/* select buttons */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center cursor-pointer"
            onClick={() => setSelect(1)}
          >
            {select === 1 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Pay with Debit/credit card
          </h4>
        </div>

        {/* pay with card */}
        {select === 1 ? (
          <div className="w-full flex border-b">
            <form className="w-full" onSubmit={paymentHandler}>
              <div className="w-full flex pb-3">
                <div className="w-[50%]">
                  <label className="block pb-2">Card Number</label>
                  <input required className={`${styles.input} !w-[95%]`} />
                </div>
                <div className="w-[50%]">
                  <label className="block pb-2">Exp Date</label>
                  <input type="number" required className={`${styles.input}`} />
                </div>
              </div>

              <div className="w-full flex pb-3">
                <div className="w-[50%]">
                  <label className="block pb-2">Name On Card</label>
                  <input required className={`${styles.input} !w-[95%]`} />
                </div>
                <div className="w-[50%]">
                  <label className="block pb-2">Billing Address</label>
                  <input type="text" required className={`${styles.input}`} />
                </div>
              </div>
              <input
                type="submit"
                value="Submit"
                className={`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
              />
            </form>
          </div>
        ) : null}
      </div>

      <br />
      {/* Pay With Esewa */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center cursor-pointer"
            onClick={() => setSelect(3)}
          >
            {select === 3 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Pay with Esewa
          </h4>
        </div>

        {/* Pay With Esewa */}
        {select === 3 ? (
          <div className="w-full flex border-b">
            <form className="w-full" onSubmit={paymentHandler}>
              <div className="w-[50%]">
                <label className="block pb-2">QR Code</label>
                <img src={eSewa_QR} className={`${styles.input} !w-[95%]`} />
              </div>
              <div>
                <label className="pb-2">
                  Upload Your Screenshot<span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  name=""
                  id="upload"
                  className="hidden"
                  required
                  onChange={handleImageChange}
                />
                <div className="w-full flex items-center flex-wrap">
                  <label htmlFor="upload">
                    <AiOutlinePlusCircle
                      size={30}
                      className="mt-3 cursor-pointer"
                      color="#555"
                    />
                  </label>
                  {images &&
                    images.map((i) => (
                      <img
                        src={URL.createObjectURL(i)}
                        key={i}
                        alt=""
                        className="h-[120px] w-[120px] object-cover m-2"
                      />
                    ))}
                </div>
                <br />
                <div>
                  <input
                    type="submit"
                    value="Submit"
                    className={`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
                  />
                </div>
              </div>
            </form>
          </div>
        ) : null}
      </div>

      <br />
      {/* Pay With ImePay */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center cursor-pointer"
            onClick={() => setSelect(4)}
          >
            {select === 4 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Pay with ImePay
          </h4>
        </div>

        {/* Pay With ImePay */}
        {select === 4 ? (
          <div className="w-full flex border-b">
            <form className="w-full" onSubmit={paymentHandler}>
              <div className="w-[50%]">
                <label className="block pb-2">QR Code</label>
                <img src={Imepay_QR} className={`${styles.input} !w-[95%]`} />
              </div>
              <br />
              <div>
                <label className="pb-2">
                  Upload Your Screenshot<span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  name=""
                  id="upload"
                  className="hidden"
                  required
                  onChange={handleImageChange}
                />
                <div className="w-full flex items-center flex-wrap">
                  <label htmlFor="upload">
                    <AiOutlinePlusCircle
                      size={30}
                      className="mt-3 cursor-pointer"
                      color="#555"
                    />
                  </label>
                  {images &&
                    images.map((i) => (
                      <img
                        src={URL.createObjectURL(i)}
                        key={i}
                        alt=""
                        className="h-[120px] w-[120px] object-cover m-2"
                      />
                    ))}
                </div>
                <br />
                <div>
                  <input
                    type="submit"
                    value="Submit"
                    className={`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
                  />
                </div>
              </div>
            </form>
          </div>
        ) : null}
      </div>

      <br />
      {/* Pay With Khalti */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center cursor-pointer"
            onClick={() => setSelect(5)}
          >
            {select === 5 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Pay with Khalti
          </h4>
        </div>

        {/* Pay With Khalti */}
        {select === 5 ? (
          <div className="w-full flex border-b">
            <form className="w-full" onSubmit={paymentHandler}>
              <div className="w-[50%]">
                <label className="block pb-2">QR Code</label>
                <img src={Khalti_QR} className={`${styles.input} !w-[95%]`} />
              </div>
              <br />
              <div>
                <label className="pb-2">
                  Upload Your Screenshot<span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  name=""
                  id="upload"
                  className="hidden"
                  required
                  onChange={handleImageChange}
                />
                <div className="w-full flex items-center flex-wrap">
                  <label htmlFor="upload">
                    <AiOutlinePlusCircle
                      size={30}
                      className="mt-3 cursor-pointer"
                      color="#555"
                    />
                  </label>
                  {images &&
                    images.map((i) => (
                      <img
                        src={URL.createObjectURL(i)}
                        key={i}
                        alt=""
                        className="h-[120px] w-[120px] object-cover m-2"
                      />
                    ))}
                </div>
                <br />
                <div>
                  <input
                    type="submit"
                    value="Submit"
                    className={`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
                  />
                </div>
              </div>
            </form>
          </div>
        ) : null}
      </div>

      <br />
      {/* Pay With Mobile Banking */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center cursor-pointer"
            onClick={() => setSelect(6)}
          >
            {select === 6 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Pay with Mobile Banking
          </h4>
        </div>

        {/* Pay With Mobile Banking */}
        {select === 6 ? (
          <div className="w-full flex border-b">
            <form className="w-full" onSubmit={paymentHandler}>
              <div className="w-[50%]">
                <label className="block pb-2">QR Code - Nic Asia Bank</label>
                <img src={Bank_QR} className={`${styles.input} !w-[95%]`} />
              </div>
              <br />
              <div>
                <label className="pb-2">
                  Upload Your Screenshot<span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  name=""
                  id="upload"
                  className="hidden"
                  required
                  onChange={handleImageChange}
                />
                <div className="w-full flex items-center flex-wrap">
                  <label htmlFor="upload">
                    <AiOutlinePlusCircle
                      size={30}
                      className="mt-3 cursor-pointer"
                      color="#555"
                    />
                  </label>
                  {images &&
                    images.map((i) => (
                      <img
                        src={URL.createObjectURL(i)}
                        key={i}
                        alt=""
                        className="h-[120px] w-[120px] object-cover m-2"
                      />
                    ))}
                </div>
                <br />
                <div>
                  <input
                    type="submit"
                    value="Submit"
                    className={`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
                  />
                </div>
              </div>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  );
};
const CartData = () => {
  return (
    <div className="w-full bg-[#fff] rounded-md p-5 pb-8">
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Sub-Total:</h3>
        <h5 className="text-[18px] font-[600]">$2610.00</h5>
      </div>
      <br />
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Shipping:</h3>
        <h5 className="text-[18px] font-[600]">-</h5>
      </div>
      <br />
      <div className="flex justify-between border-b pb-3">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Discount:</h3>
        <h5 className="text-[18px] font-[600]">-</h5>
      </div>
      <h5 className="text-[18px] font-[600] text-end pt-3">$2610.00</h5>
      <br />
      <form>
        <input
          type="text"
          className={`${styles.input} h-[40px] pl-2`}
          placeholder="Coupoun code"
          required
        />
        <input
          className={`w-full h-[40px] border border-[#f63b60] text-center text-[#f63b60] rounded-[3px] mt-8 cursor-pointer`}
          required
          value="Apply code"
          type="submit"
        />
      </form>
    </div>
  );
};

export default Payment;
