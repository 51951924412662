import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import ProductDetails from "../components/Products/ProductDetails";
import SuggestedProduct from "../components/Products/SuggestedProduct";
import { useSelector } from "react-redux";
import { server } from "../server";
import Helmet from "../components/Helmet/Helmet";

const ProductDetailsPage = () => {
  // const { allProducts } = useSelector((state) => state.products);

  const { name } = useParams();
  const productName = name.replace(/%20/g, " ");
  const [data, setData] = useState(null);

  // useEffect(() => {
  //   const data = allProducts && allProducts.find((i) => i.name === productName);
  //   setData(data);
  // }, []);

  useEffect(() => {
    const getProductByName = async () => {
      try {
        const response = await fetch(
          `${server}/product/product-details/${productName}`
        );
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const data = await response.json();
        setData(data.product);
      } catch (error) {
        console.error(error);
      }
    };
    getProductByName();
  }, [productName]);

  return (
    <Helmet title={data.name}>
      <div>
        <Header />
        <ProductDetails data={data} />
        {data && <SuggestedProduct data={data} />}
        <Footer />
      </div>
    </Helmet>
  );
};

export default ProductDetailsPage;
