import React from "react";
import CheckoutSteps from "../components/Checkout/CheckoutSteps";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Payment from "../components/Payment/Payment";
import Helmet from "../components/Helmet/Helmet";

const PaymentPage = () => {
  return (
    <Helmet title="Payment">
      <div className="w-full min-h-screen bg-[#f6f9fc]">
        <Header />
        <br />
        <br />
        <CheckoutSteps active={2} />
        <Payment />
        <br />
        <br />
        <Footer />
      </div>
    </Helmet>
  );
};

export default PaymentPage;
